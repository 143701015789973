export default {
  Content: [
    {
      techNo: 15, // 게시글 번호
      labNo: 7263, // 연구실 번호
      techDate: '2022-08-30', // 작성일
      techStatus: '완료', // 상태
      techName: '백합 구근의 수확후 저장성 증대 기술 개발', // 기술 명
      techNameEn: '', // 기술 영문 명
      techSummary:
        '백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅- 구근 부패병 방제 기술 개발- 구근의 적정동결저장 및 해동실험 - 농가의 구근 저장고 실태 조사- 백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅  - 구근 부패병(peicillim, pythium, fusarium 등) 방제 기술 개발 - 구근 저장 배지의 온·습도에 따른 구근 품질 분석 및...', // 기술 개요
      techSummaryEn: '', // 기술 개요 영문
      techApplyField: '농업, 임업 및 어업', // 적용 분야
      techApplyFieldEn: '', // 적용 분야 영문
      techTransferType: '기술매매', // 기술 이전 유형
      techGrowLevel: '3', // 기술 성숙도 단계
      techTransferYear: '2014', // 이전 연도
      techTransferResult:
        '농가 현장 컨설팅 교육- 백합 구근의 수확후 저장성 증대를 위한 현장 자문 교육- 농가의 현장 컨설팅 교육- 매뉴얼 책자 보급', // 기술 이전 성과(기업)
      techTransferResultEn: '', // 기술 이전 성과(기업) 영문
      techFollowUpSupport:
        '백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅  - 구근 부패병(peicillim, pythium, fusarium 등) 방제 기술 개발 - 구근 저장 배지의 온·습도에 따른 구근 품질 분석 및 유해 미생물 발생 억제 기술 개발', // 후속 지원
      techFollowUpSupportEn: '', // 후속 지원 영문
      techProgress: '', // 진행 상황
      techSpecialNote: '', // 기술 이전 특이사항
      techSpecialNoteEn: '' // 기술 이전 특이사항 영문
    },
    {
      techNo: 14,
      labNo: 6263,
      techDate: '2022-08-26',
      techStatus: '완료',
      techName: '복숭아 수확후 연화억제 및 감모율 경감 기술개발',
      techNameEn: 'Distribution of New Korean Cultivars and Reduction of Environmental Damages in Peach',
      techSummary:
        '1MCP와 CA 조건에 따른 복숭아의 연화억제 효과분석   품종 : 2품종    CA 조건 : 3조건   연화정도 : 과실의 생리화학적 성분 분석 □ 수확시기에 따른 감모율 경감의 CA 효과분석   수확시기 : 3회 수확   CA 조건 : 3조건   과실의 병해발생도   품질과 관련된 요인 분석 : 호흡, 에틸렌, 색도 □ 복숭아 신선유통을 위한 최적 ...',
      techSummaryEn: '',
      techApplyField: '원예작물과학',
      techApplyFieldEn: '',
      techTransferType: '라이센스',
      techGrowLevel: '4',
      techTransferYear: '2016',
      techTransferResult:
        '기술적 측면   검역기준 강화 대비 병해충 경감기술의 적용으로 GAP 국제기준 확보가 가능함   과육 연화방지 기술과 친환경적인 CA 저장기술의 수출현장 적용   복숭아의 CA 운영기술에 대한 원천기술 확보가 가능함 □ 경제적․산업적 측면(경제적면에서는 기대 성과물의 가치를 돈으로 표시)   생리장해 및 저장병해 발생 억제로 시장성확충 및 가격경쟁력 확...',
      techTransferResultEn: '',
      techFollowUpSupport:
        '복숭아 수확 후 연화억제 및 감모율 경감기술 개발    1MCP와 CA 조건에 따른 복숭아의 연화억제 효과분석   수확시기에 따른 감모율 경감의 CA 효과분석      복숭아 신선유통을 위한 최적 1MCP와 CA 복합처리 조건 확립',
      techFollowUpSupportEn: '',
      techProgress: '',
      techSpecialNote: '',
      techSpecialNoteEn: ''
    },
    {
      techNo: 13,
      labNo: 1234,
      techDate: '2022-08-16',
      techStatus: '진행중',
      techName: '전파간섭 극복 기반 근거리 무선전송(Post-WiFi) 핵심 기술 개발',
      techNameEn: 'Short Range Wireless Transmission Technology with Robustness to Radio Interferences',
      techSummary:
        '간섭정렬 Precoder/Decoder 설계  - 무선랜 부분 대역 간섭 완화를 위한 간섭정렬 알고리즘 설계  - Downlink/Uplink 전송 모드를 고려한 간섭정렬 알고리즘 설계  - 부분 채널정보 기반의 간섭정렬 알고리즘 설계  - 사용자/채널상태 동시고려 간섭정렬 알고리즘 설계  - 간섭정렬 알고리즘 상호 적용 방법 정립o 간섭정렬 적용 지원...',
      techSummaryEn: 'Interference Alignment,QoS,Interference Detection,Interference Avoidance,MIMO Antenna',
      techApplyField: '제조업(전자부품, 컴퓨터, 영상, 음향 및 통신장비)',
      techApplyFieldEn: '',
      techTransferType: '기술매매',
      techGrowLevel: '6',
      techTransferYear: '',
      techTransferResult: '',
      techTransferResultEn: '',
      techFollowUpSupport: '',
      techFollowUpSupportEn: '',
      techProgress: '선행 표준 규격 시스템 시뮬레이터 개발 및 알고리즘 성능 검증',
      techSpecialNote: '무선랜 간섭문제 완화ㅇ무선랜 전송품질 향상ㅇIEEE 802.11, 802.19 국제 표준화 및 표준지재권 확보',
      techSpecialNoteEn: ''
    },
    {
      techNo: 12,
      labNo: 5634,
      techDate: '2022-08-12',
      techStatus: '진행중',
      techName: '스펙트럼공학 및 밀리미터파대 전파자원 이용기술 개발',
      techNameEn: 'Development of Spectrum Engineering and Millimeterwave Utilizing Technology',
      techSummary:
        '기술 중립성을 위한 간섭 분석 기술 개발o 신규 무선 서비스 도입을 위한 간섭 분석o 기술 중립성 기반 간섭분석 S/W 구현o 4G 시스템 레벨 간섭 분석 기술 개발    o 밀리미터파 대역에서 Specular와 Rough surface의 반사특성 측정o 70/80 ㎓ 대역 전파전파 특성 연구o 밀리미터파 대역 실내, 실외 정밀 전파특성 예측 툴 개발 ...',
      techSummaryEn:
        'channel sounder, millimeter wave, transceiver, Satellite system, frequency sharing, ITU, Assessing interference, Techno-Economic Analysis',
      techApplyField: '출판, 영상, 방송통신, 콘텐츠 및 정보서비스업',
      techApplyFieldEn: '',
      techTransferType: '라이센스',
      techGrowLevel: '5',
      techTransferYear: '',
      techTransferResult: '',
      techTransferResultEn: '',
      techFollowUpSupport: '',
      techFollowUpSupportEn: '',
      techProgress:
        '기술 중립성 기반 주파수 간섭분석 기술 및 툴 개발  o UHF(700MHz) 및 밀리미터파대역 광대역 전파모델 개발 및 정밀 예측 툴 개발  o 다중빔 위성망과 타 무선망간 효율적 주파수 공유 방안 연구  o 90 ㎓대 송수신기 구성 모듈 개발  o 전파자원 기술경제성 분석 방법론 개발 및 적용',
      techSpecialNote: '기술 중립성 도입으로 신규 기술 또는 사업자의 진입 장벽을 완화하여 새로운 산업적 경제적 가치를 창출할 것으로 예상',
      techSpecialNoteEn: ''
    },
    {
      techNo: 11,
      labNo: 9019,
      techDate: '2022-08-10',
      techStatus: '완료',
      techName: '인공전자기 구조를 이용한 차세대 전파 센서 기술 개발',
      techNameEn: 'Development of next generation radio sensor technology using artificial electromagnetic structures',
      techSummary:
        '원거리 결상 고 분해능 전파 이미지 센서동작 주파수 파장의 4분의 1보다 작은 물체의 이미지를 원거리 장 영역에서 복원할 수 있는 원거리 결상 고 분해능 전파 이미지 센서의 개발. 안테나의 최대 크기가 파장의 10분의 1 보다 작고 3dB 대역폭이 동작 중심 주파수 기준 7%이상 이며 안테나 효율이 70% 이상인 인공 전자기 구조 기반 차세대 안테나 개발....',
      techSummaryEn: 'Electromagnetic wave image sensor, small antenna, high-gain antenna',
      techApplyField: '제조업(전자부품, 컴퓨터, 영상, 음향 및 통신장비)',
      techApplyFieldEn: '',
      techTransferType: '기술매매',
      techGrowLevel: '2',
      techTransferYear: '2020',
      techTransferResult:
        '기술적으로 지금까지의 설계 개념과는 다른 신 개념의 전자파 특성을 이용한 차세대 전자/통신/무선 부품 설계 기반 기술 확보. 인공전자기구조(인공전자기)응용 기술의 독자적인 세계 최고 기술 확보를 통하여 차세대 전자/통신/무선 부품들의 설계 패러다임 변혁 및 성능 고도화. 국내·외 시장 조기 개척과 응용 관련 부품 수출입대체 효과 제고 및 IT·BT·NT 융...',
      techTransferResultEn: '',
      techFollowUpSupport:
        '2021년까지 파장의 1/10 이하의 세계 최고의 균질(homogeneous) 성능을 갖는 인공 전자기 구조를 이용한 차세대 전파 센서 기술 개발',
      techFollowUpSupportEn: '',
      techProgress: '',
      techSpecialNote: '',
      techSpecialNoteEn: ''
    },
    {
      techNo: 10, // 게시글 번호
      labNo: 7263, // 연구실 번호
      techDate: '2022-08-30', // 작성일
      techStatus: '완료', // 상태
      techName: '백합 구근의 수확후 저장성 증대 기술 개발', // 기술 명
      techNameEn: '', // 기술 영문 명
      techSummary:
        '백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅- 구근 부패병 방제 기술 개발- 구근의 적정동결저장 및 해동실험 - 농가의 구근 저장고 실태 조사- 백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅  - 구근 부패병(peicillim, pythium, fusarium 등) 방제 기술 개발 - 구근 저장 배지의 온·습도에 따른 구근 품질 분석 및...', // 기술 개요
      techSummaryEn: '', // 기술 개요 영문
      techApplyField: '농업, 임업 및 어업', // 적용 분야
      techApplyFieldEn: '', // 적용 분야 영문
      techTransferType: '기술매매', // 기술 이전 유형
      techGrowLevel: '3', // 기술 성숙도 단계
      techTransferYear: '2014', // 이전 연도
      techTransferResult:
        '농가 현장 컨설팅 교육- 백합 구근의 수확후 저장성 증대를 위한 현장 자문 교육- 농가의 현장 컨설팅 교육- 매뉴얼 책자 보급', // 기술 이전 성과(기업)
      techTransferResultEn: '', // 기술 이전 성과(기업) 영문
      techFollowUpSupport:
        '백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅  - 구근 부패병(peicillim, pythium, fusarium 등) 방제 기술 개발 - 구근 저장 배지의 온·습도에 따른 구근 품질 분석 및 유해 미생물 발생 억제 기술 개발', // 후속 지원
      techFollowUpSupportEn: '', // 후속 지원 영문
      techProgress: '', // 진행 상황
      techSpecialNote: '', // 기술 이전 특이사항
      techSpecialNoteEn: '' // 기술 이전 특이사항 영문
    },
    {
      techNo: 9,
      labNo: 6263,
      techDate: '2022-08-26',
      techStatus: '완료',
      techName: '복숭아 수확후 연화억제 및 감모율 경감 기술개발',
      techNameEn: 'Distribution of New Korean Cultivars and Reduction of Environmental Damages in Peach',
      techSummary:
        '1MCP와 CA 조건에 따른 복숭아의 연화억제 효과분석   품종 : 2품종    CA 조건 : 3조건   연화정도 : 과실의 생리화학적 성분 분석 □ 수확시기에 따른 감모율 경감의 CA 효과분석   수확시기 : 3회 수확   CA 조건 : 3조건   과실의 병해발생도   품질과 관련된 요인 분석 : 호흡, 에틸렌, 색도 □ 복숭아 신선유통을 위한 최적 ...',
      techSummaryEn: '',
      techApplyField: '원예작물과학',
      techApplyFieldEn: '',
      techTransferType: '라이센스',
      techGrowLevel: '4',
      techTransferYear: '2016',
      techTransferResult:
        '기술적 측면   검역기준 강화 대비 병해충 경감기술의 적용으로 GAP 국제기준 확보가 가능함   과육 연화방지 기술과 친환경적인 CA 저장기술의 수출현장 적용   복숭아의 CA 운영기술에 대한 원천기술 확보가 가능함 □ 경제적․산업적 측면(경제적면에서는 기대 성과물의 가치를 돈으로 표시)   생리장해 및 저장병해 발생 억제로 시장성확충 및 가격경쟁력 확...',
      techTransferResultEn: '',
      techFollowUpSupport:
        '복숭아 수확 후 연화억제 및 감모율 경감기술 개발    1MCP와 CA 조건에 따른 복숭아의 연화억제 효과분석   수확시기에 따른 감모율 경감의 CA 효과분석      복숭아 신선유통을 위한 최적 1MCP와 CA 복합처리 조건 확립',
      techFollowUpSupportEn: '',
      techProgress: '',
      techSpecialNote: '',
      techSpecialNoteEn: ''
    },
    {
      techNo: 8,
      labNo: 1234,
      techDate: '2022-08-16',
      techStatus: '진행중',
      techName: '전파간섭 극복 기반 근거리 무선전송(Post-WiFi) 핵심 기술 개발',
      techNameEn: 'Short Range Wireless Transmission Technology with Robustness to Radio Interferences',
      techSummary:
        '간섭정렬 Precoder/Decoder 설계  - 무선랜 부분 대역 간섭 완화를 위한 간섭정렬 알고리즘 설계  - Downlink/Uplink 전송 모드를 고려한 간섭정렬 알고리즘 설계  - 부분 채널정보 기반의 간섭정렬 알고리즘 설계  - 사용자/채널상태 동시고려 간섭정렬 알고리즘 설계  - 간섭정렬 알고리즘 상호 적용 방법 정립o 간섭정렬 적용 지원...',
      techSummaryEn: 'Interference Alignment,QoS,Interference Detection,Interference Avoidance,MIMO Antenna',
      techApplyField: '제조업(전자부품, 컴퓨터, 영상, 음향 및 통신장비)',
      techApplyFieldEn: '',
      techTransferType: '기술매매',
      techGrowLevel: '6',
      techTransferYear: '',
      techTransferResult: '',
      techTransferResultEn: '',
      techFollowUpSupport: '',
      techFollowUpSupportEn: '',
      techProgress: '선행 표준 규격 시스템 시뮬레이터 개발 및 알고리즘 성능 검증',
      techSpecialNote: '무선랜 간섭문제 완화ㅇ무선랜 전송품질 향상ㅇIEEE 802.11, 802.19 국제 표준화 및 표준지재권 확보',
      techSpecialNoteEn: ''
    },
    {
      techNo: 7,
      labNo: 5634,
      techDate: '2022-08-12',
      techStatus: '진행중',
      techName: '스펙트럼공학 및 밀리미터파대 전파자원 이용기술 개발',
      techNameEn: 'Development of Spectrum Engineering and Millimeterwave Utilizing Technology',
      techSummary:
        '기술 중립성을 위한 간섭 분석 기술 개발o 신규 무선 서비스 도입을 위한 간섭 분석o 기술 중립성 기반 간섭분석 S/W 구현o 4G 시스템 레벨 간섭 분석 기술 개발    o 밀리미터파 대역에서 Specular와 Rough surface의 반사특성 측정o 70/80 ㎓ 대역 전파전파 특성 연구o 밀리미터파 대역 실내, 실외 정밀 전파특성 예측 툴 개발 ...',
      techSummaryEn:
        'channel sounder, millimeter wave, transceiver, Satellite system, frequency sharing, ITU, Assessing interference, Techno-Economic Analysis',
      techApplyField: '출판, 영상, 방송통신, 콘텐츠 및 정보서비스업',
      techApplyFieldEn: '',
      techTransferType: '라이센스',
      techGrowLevel: '5',
      techTransferYear: '',
      techTransferResult: '',
      techTransferResultEn: '',
      techFollowUpSupport: '',
      techFollowUpSupportEn: '',
      techProgress:
        '기술 중립성 기반 주파수 간섭분석 기술 및 툴 개발  o UHF(700MHz) 및 밀리미터파대역 광대역 전파모델 개발 및 정밀 예측 툴 개발  o 다중빔 위성망과 타 무선망간 효율적 주파수 공유 방안 연구  o 90 ㎓대 송수신기 구성 모듈 개발  o 전파자원 기술경제성 분석 방법론 개발 및 적용',
      techSpecialNote: '기술 중립성 도입으로 신규 기술 또는 사업자의 진입 장벽을 완화하여 새로운 산업적 경제적 가치를 창출할 것으로 예상',
      techSpecialNoteEn: ''
    },
    {
      techNo: 6,
      labNo: 9019,
      techDate: '2022-08-10',
      techStatus: '완료',
      techName: '인공전자기 구조를 이용한 차세대 전파 센서 기술 개발',
      techNameEn: 'Development of next generation radio sensor technology using artificial electromagnetic structures',
      techSummary:
        '원거리 결상 고 분해능 전파 이미지 센서동작 주파수 파장의 4분의 1보다 작은 물체의 이미지를 원거리 장 영역에서 복원할 수 있는 원거리 결상 고 분해능 전파 이미지 센서의 개발. 안테나의 최대 크기가 파장의 10분의 1 보다 작고 3dB 대역폭이 동작 중심 주파수 기준 7%이상 이며 안테나 효율이 70% 이상인 인공 전자기 구조 기반 차세대 안테나 개발....',
      techSummaryEn: 'Electromagnetic wave image sensor, small antenna, high-gain antenna',
      techApplyField: '제조업(전자부품, 컴퓨터, 영상, 음향 및 통신장비)',
      techApplyFieldEn: '',
      techTransferType: '기술매매',
      techGrowLevel: '2',
      techTransferYear: '2020',
      techTransferResult:
        '기술적으로 지금까지의 설계 개념과는 다른 신 개념의 전자파 특성을 이용한 차세대 전자/통신/무선 부품 설계 기반 기술 확보. 인공전자기구조(인공전자기)응용 기술의 독자적인 세계 최고 기술 확보를 통하여 차세대 전자/통신/무선 부품들의 설계 패러다임 변혁 및 성능 고도화. 국내·외 시장 조기 개척과 응용 관련 부품 수출입대체 효과 제고 및 IT·BT·NT 융...',
      techTransferResultEn: '',
      techFollowUpSupport:
        '2021년까지 파장의 1/10 이하의 세계 최고의 균질(homogeneous) 성능을 갖는 인공 전자기 구조를 이용한 차세대 전파 센서 기술 개발',
      techFollowUpSupportEn: '',
      techProgress: '',
      techSpecialNote: '',
      techSpecialNoteEn: ''
    },
    {
      techNo: 5, // 게시글 번호
      labNo: 7263, // 연구실 번호
      techDate: '2022-08-30', // 작성일
      techStatus: '완료', // 상태
      techName: '백합 구근의 수확후 저장성 증대 기술 개발', // 기술 명
      techNameEn: '', // 기술 영문 명
      techSummary:
        '백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅- 구근 부패병 방제 기술 개발- 구근의 적정동결저장 및 해동실험 - 농가의 구근 저장고 실태 조사- 백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅  - 구근 부패병(peicillim, pythium, fusarium 등) 방제 기술 개발 - 구근 저장 배지의 온·습도에 따른 구근 품질 분석 및...', // 기술 개요
      techSummaryEn: '', // 기술 개요 영문
      techApplyField: '농업, 임업 및 어업', // 적용 분야
      techApplyFieldEn: '', // 적용 분야 영문
      techTransferType: '기술매매', // 기술 이전 유형
      techGrowLevel: '3', // 기술 성숙도 단계
      techTransferYear: '2014', // 이전 연도
      techTransferResult:
        '농가 현장 컨설팅 교육- 백합 구근의 수확후 저장성 증대를 위한 현장 자문 교육- 농가의 현장 컨설팅 교육- 매뉴얼 책자 보급', // 기술 이전 성과(기업)
      techTransferResultEn: '', // 기술 이전 성과(기업) 영문
      techFollowUpSupport:
        '백합 구근 수확후 최적 저장조건 구명 및 현장 컨설팅  - 구근 부패병(peicillim, pythium, fusarium 등) 방제 기술 개발 - 구근 저장 배지의 온·습도에 따른 구근 품질 분석 및 유해 미생물 발생 억제 기술 개발', // 후속 지원
      techFollowUpSupportEn: '', // 후속 지원 영문
      techProgress: '', // 진행 상황
      techSpecialNote: '', // 기술 이전 특이사항
      techSpecialNoteEn: '' // 기술 이전 특이사항 영문
    },
    {
      techNo: 4,
      labNo: 6263,
      techDate: '2022-08-26',
      techStatus: '완료',
      techName: '복숭아 수확후 연화억제 및 감모율 경감 기술개발',
      techNameEn: 'Distribution of New Korean Cultivars and Reduction of Environmental Damages in Peach',
      techSummary:
        '1MCP와 CA 조건에 따른 복숭아의 연화억제 효과분석   품종 : 2품종    CA 조건 : 3조건   연화정도 : 과실의 생리화학적 성분 분석 □ 수확시기에 따른 감모율 경감의 CA 효과분석   수확시기 : 3회 수확   CA 조건 : 3조건   과실의 병해발생도   품질과 관련된 요인 분석 : 호흡, 에틸렌, 색도 □ 복숭아 신선유통을 위한 최적 ...',
      techSummaryEn: '',
      techApplyField: '원예작물과학',
      techApplyFieldEn: '',
      techTransferType: '라이센스',
      techGrowLevel: '4',
      techTransferYear: '2016',
      techTransferResult:
        '기술적 측면   검역기준 강화 대비 병해충 경감기술의 적용으로 GAP 국제기준 확보가 가능함   과육 연화방지 기술과 친환경적인 CA 저장기술의 수출현장 적용   복숭아의 CA 운영기술에 대한 원천기술 확보가 가능함 □ 경제적․산업적 측면(경제적면에서는 기대 성과물의 가치를 돈으로 표시)   생리장해 및 저장병해 발생 억제로 시장성확충 및 가격경쟁력 확...',
      techTransferResultEn: '',
      techFollowUpSupport:
        '복숭아 수확 후 연화억제 및 감모율 경감기술 개발    1MCP와 CA 조건에 따른 복숭아의 연화억제 효과분석   수확시기에 따른 감모율 경감의 CA 효과분석      복숭아 신선유통을 위한 최적 1MCP와 CA 복합처리 조건 확립',
      techFollowUpSupportEn: '',
      techProgress: '',
      techSpecialNote: '',
      techSpecialNoteEn: ''
    },
    {
      techNo: 3,
      labNo: 1234,
      techDate: '2022-08-16',
      techStatus: '진행중',
      techName: '전파간섭 극복 기반 근거리 무선전송(Post-WiFi) 핵심 기술 개발',
      techNameEn: 'Short Range Wireless Transmission Technology with Robustness to Radio Interferences',
      techSummary:
        '간섭정렬 Precoder/Decoder 설계  - 무선랜 부분 대역 간섭 완화를 위한 간섭정렬 알고리즘 설계  - Downlink/Uplink 전송 모드를 고려한 간섭정렬 알고리즘 설계  - 부분 채널정보 기반의 간섭정렬 알고리즘 설계  - 사용자/채널상태 동시고려 간섭정렬 알고리즘 설계  - 간섭정렬 알고리즘 상호 적용 방법 정립o 간섭정렬 적용 지원...',
      techSummaryEn: 'Interference Alignment,QoS,Interference Detection,Interference Avoidance,MIMO Antenna',
      techApplyField: '제조업(전자부품, 컴퓨터, 영상, 음향 및 통신장비)',
      techApplyFieldEn: '',
      techTransferType: '기술매매',
      techGrowLevel: '6',
      techTransferYear: '',
      techTransferResult: '',
      techTransferResultEn: '',
      techFollowUpSupport: '',
      techFollowUpSupportEn: '',
      techProgress: '선행 표준 규격 시스템 시뮬레이터 개발 및 알고리즘 성능 검증',
      techSpecialNote: '무선랜 간섭문제 완화ㅇ무선랜 전송품질 향상ㅇIEEE 802.11, 802.19 국제 표준화 및 표준지재권 확보',
      techSpecialNoteEn: ''
    },
    {
      techNo: 2,
      labNo: 5634,
      techDate: '2022-08-12',
      techStatus: '진행중',
      techName: '스펙트럼공학 및 밀리미터파대 전파자원 이용기술 개발',
      techNameEn: 'Development of Spectrum Engineering and Millimeterwave Utilizing Technology',
      techSummary:
        '기술 중립성을 위한 간섭 분석 기술 개발o 신규 무선 서비스 도입을 위한 간섭 분석o 기술 중립성 기반 간섭분석 S/W 구현o 4G 시스템 레벨 간섭 분석 기술 개발    o 밀리미터파 대역에서 Specular와 Rough surface의 반사특성 측정o 70/80 ㎓ 대역 전파전파 특성 연구o 밀리미터파 대역 실내, 실외 정밀 전파특성 예측 툴 개발 ...',
      techSummaryEn:
        'channel sounder, millimeter wave, transceiver, Satellite system, frequency sharing, ITU, Assessing interference, Techno-Economic Analysis',
      techApplyField: '출판, 영상, 방송통신, 콘텐츠 및 정보서비스업',
      techApplyFieldEn: '',
      techTransferType: '라이센스',
      techGrowLevel: '5',
      techTransferYear: '',
      techTransferResult: '',
      techTransferResultEn: '',
      techFollowUpSupport: '',
      techFollowUpSupportEn: '',
      techProgress:
        '기술 중립성 기반 주파수 간섭분석 기술 및 툴 개발  o UHF(700MHz) 및 밀리미터파대역 광대역 전파모델 개발 및 정밀 예측 툴 개발  o 다중빔 위성망과 타 무선망간 효율적 주파수 공유 방안 연구  o 90 ㎓대 송수신기 구성 모듈 개발  o 전파자원 기술경제성 분석 방법론 개발 및 적용',
      techSpecialNote: '기술 중립성 도입으로 신규 기술 또는 사업자의 진입 장벽을 완화하여 새로운 산업적 경제적 가치를 창출할 것으로 예상',
      techSpecialNoteEn: ''
    },
    {
      techNo: 1,
      labNo: 9019,
      techDate: '2022-08-10',
      techStatus: '완료',
      techName: '인공전자기 구조를 이용한 차세대 전파 센서 기술 개발',
      techNameEn: 'Development of next generation radio sensor technology using artificial electromagnetic structures',
      techSummary:
        '원거리 결상 고 분해능 전파 이미지 센서동작 주파수 파장의 4분의 1보다 작은 물체의 이미지를 원거리 장 영역에서 복원할 수 있는 원거리 결상 고 분해능 전파 이미지 센서의 개발. 안테나의 최대 크기가 파장의 10분의 1 보다 작고 3dB 대역폭이 동작 중심 주파수 기준 7%이상 이며 안테나 효율이 70% 이상인 인공 전자기 구조 기반 차세대 안테나 개발....',
      techSummaryEn: 'Electromagnetic wave image sensor, small antenna, high-gain antenna',
      techApplyField: '제조업(전자부품, 컴퓨터, 영상, 음향 및 통신장비)',
      techApplyFieldEn: '',
      techTransferType: '기술매매',
      techGrowLevel: '2',
      techTransferYear: '2020',
      techTransferResult:
        '기술적으로 지금까지의 설계 개념과는 다른 신 개념의 전자파 특성을 이용한 차세대 전자/통신/무선 부품 설계 기반 기술 확보. 인공전자기구조(인공전자기)응용 기술의 독자적인 세계 최고 기술 확보를 통하여 차세대 전자/통신/무선 부품들의 설계 패러다임 변혁 및 성능 고도화. 국내·외 시장 조기 개척과 응용 관련 부품 수출입대체 효과 제고 및 IT·BT·NT 융...',
      techTransferResultEn: '',
      techFollowUpSupport:
        '2021년까지 파장의 1/10 이하의 세계 최고의 균질(homogeneous) 성능을 갖는 인공 전자기 구조를 이용한 차세대 전파 센서 기술 개발',
      techFollowUpSupportEn: '',
      techProgress: '',
      techSpecialNote: '',
      techSpecialNoteEn: ''
    }
  ]
}
