//INFO: 산학협력 게시판 // TODO: 연구실 관리 계정으로 페이지에 들어오면 해당 연구실의 Data만 보여주기
<script>
import { getTechHandover } from '@/api/platform/community'
import { deleteHandover } from '@/api/admin/ntis/ntisDelete'
import techTransferData from '@/data/techTransfer' // 더미데이터 들어있는 js
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'
import AddBtn from '@/components/ui/AddBtn.vue' // 산학협력 등록
// INFO: 검색 기능
import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'adminBoardResultList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    // INFO: 검색 기능
    Dropdown
  },
  data() {
    const labNo = Number(this.$route.params.labNo)

    return {
      currentPath: this.$route.fullPath, // 현재 접속 경로
      currentPage: '', // 현재 페이지 번호
      techGrid: '', // 산학협력 그리드
      labNo, // 연구실 번호

      // ********** 그리드 세팅 **********
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      formatoptions: { type: 'dateTime', format: 'y/M/d' }, // 컬럼 날짜 포맷. 시간까지 보이게 하려면 format: 'M/d/y hh:mm a'
      toolbar: ['Search'], // 툴바에 검색 옵션
      commands: [{ type: 'Delete', buttonOption: { cssClass: 'e-danger', content: '삭제' } }], // 헤더 commands 아이콘 세팅

      // ********** 산학협력 데이터 **********
      techTransferData: techTransferData.Content, // 데이터
      dataList: [],
      // INFO: 검색 기능
      persistedData: [],
      searchConditions: [
        { value: 'all', text: '전체' },
        { value: 'labNo', text: '연구실번호' },
        { value: 'techName', text: '기술명' },
        { value: 'techTransferYear', text: '이전연도 ' },
        { value: 'techApplyField', text: '적용분야' },
        { value: 'techTransferType', text: '연구실협력유형' },
        { value: 'techGrowLevel', text: '기술성숙도' },
        { value: 'techStatus', text: '상태' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },

  watch: {
    async $route() {
      await this.loadResultData()
    }
  },

  methods: {
    // ********** 산학협력 데이터 가져오기 **********
    async loadResultData() {
      const user = this.$store.state.userStore.info

      const data = user.groupName === 'admin' ? await getTechHandover() : await getTechHandover({ labNo: user.labNo })

      // 연구소 번호가 0이 아닐 때 연구소 번호에 맞는 산학협력만 보여주기
      const data2 = this.labNo ? await getTechHandover({ labNo: this.labNo }) : ''

      if (this.labNo !== 0) {
        this.dataList = data2.items
      } else {
        this.dataList = data.items
      }
    },

    // ********** 등록 버튼 **********
    goTechCreate() {
      this.$router.push({
        path: '/admin/board/result/resultCreate'
      })
    },
    // ********** 한 행 클릭 시 보기로 이동 **********
    techRowSelected(e) {
      // 그리드 현재 페이지
      this.currentPage = this.techGrid.pagerModule.pageSettings.currentPage

      // 한 행 클릭한 타켓이 버튼일 경우 리턴
      if (e.target.matches('button')) return

      // 연구소 번호가 0이 아닐 때 연구소 번호 보내주기
      if (this.labNo !== 0) {
        this.$router.push(`/admin/board/result/resultCreate/${e.data.handoverId}/${this.currentPage}/${e.data.labNo}`)
      } else {
        this.$router.push(`/admin/board/result/resultCreate/${e.data.handoverId}/${this.currentPage}/0`)
      }
    },

    // ********** 삭제 버튼 **********
    commandClick(e) {
      const target = e.target
      if (target.matches('button.e-danger')) return this.delContent(e)
    },

    // ********** 삭제 동작 **********
    async delContent(e) {
      if (!confirm('정말로 삭제하시겠습니까?')) return

      const id = e.rowData.handoverId

      const result = await deleteHandover(id)

      if (result.code) return alert(result.message)

      alert('삭제되었습니다.')
      this.dataList = this.dataList.filter((item) => item.handoverId !== id)
    },

    // INFO: 검색 기능
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      if (this.searchBy === 'all') return
      this.dataList = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    },
    checkSearchState() {
      const { labNo, board } = this.$store.state.searchStateStore

      if (board !== 'result') {
        this.$store.dispatch('searchStateStore/initailize')
        return false
      }

      if (this.labNo) return false

      if (!labNo) return false

      this.labNo = labNo
      this.$router.replace(`/admin/board/result/${labNo}`)
    }
  },

  async mounted() {
    this.isLoading = true

    // 산학협력 그리드
    this.techGrid = this.$refs.grid.ej2Instances

    // 파라미터 산학협력 그리드 페이지 번호
    const page = Number(this.$route.params.page)

    this.checkSearchState()

    // 산학협력 전체 데이터 가져오기
    await this.loadResultData()

    // 페이지 번호가 있을 때(산학협력 보기 -> 목록으로 돌아온 경우) 받아온 페이지 번호로 SET
    if (page) {
      this.techGrid.pagerModule.pageSettings.currentPage = page
    } else {
      this.techGrid.pagerModule.pageSettings.currentPage = 1
    }

    // INFO: 검색 기능
    this.setSearchOptions()
    const getList = async () => {
      this.dataList = this.dataList.map((item) => {
        return {
          handoverId: item.handoverId,
          labNo: item.labNo,
          techName: item.techName,
          techTransferYear: item.techTransferYear,
          techApplyField: item.techApplyField,
          techTransferType: item.techTransferType,
          techGrowLevel: item.techGrowLevel,
          techStatus: item.techStatus,
          createdAt: item.createdAt
        }
      })

      // INFO: 검색 기능
      this.persistedData = this.dataList
    }
    getList()
    this.isLoading = false
  }
}
</script>

<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>연구실협력성과 게시판</h4>
  </nav>

  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <!-- 제목 입력 -->

      <div>
        <!-- 등록 버튼 -->
        <AddBtn @click="goTechCreate()"></AddBtn>

        <!-- 검색기능 -->
        <Dropdown
          class="select-wrapper"
          :options="searchConditions"
          :disabled="false"
          name="zipcode"
          ref="selectOption"
          @selected="selectDropdownHandler"
        ></Dropdown>

        <!-- 기술 이전 성과 그리드 -->
        <div class="">
          <ejs-grid
            ref="grid"
            :dataSource="dataList"
            :allowPaging="true"
            :pageSettings="pageSettings"
            :allowFiltering="true"
            :allowSorting="true"
            :editSettings="editSettings"
            :toolbar="toolbar"
            :rowSelected="techRowSelected"
            :commandClick="commandClick"
            :actionComplete="actionComplete"
          >
            <e-columns>
              <e-column field="handoverId" headerText="번호" width="100" textAlign="center"></e-column>
              <e-column field="labNo" headerText="연구실번호" width="120" textAlign="center"></e-column>
              <e-column field="techName" headerText="기술명" textAlign="center"></e-column>
              <e-column field="techTransferYear" headerText="이전연도" width="120" format="y" type="date" textAlign="center"></e-column>
              <e-column field="techApplyField" headerText="적용분야" width="230" textAlign="center"></e-column>
              <e-column field="techTransferType" headerText="연구실협력유형" width="130" textAlign="center"></e-column>
              <e-column field="techGrowLevel" headerText="기술성숙도" width="120" textAlign="center"></e-column>
              <e-column field="techStatus" headerText="상태" width="90" textAlign="center"></e-column>
              <e-column headerText="관리" width="100" textAlign="left" :commands="commands"></e-column>
            </e-columns>
            <!-- <e-column field="createdAt" headerText="작성일" width="120" :format="formatoptions" type="date" textAlign="center"></e-column> -->
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 공지사항 게시판 페이지 스타일 */
.btnArea {
  height: 50px;
}
.btnArea > button {
  float: right;
}
</style>
